export const randomArrayItem = arr => arr[Math.floor(Math.random() * arr.length)];

export const hiddenPhrases = [
  'jan pawulon',
  'sprudo',
  'peef',
  'czarny pan',
  'rafau',
  'mangozjeb',
  'chinskie bajki',
  'bracia pierdolec',
].map(phrase => phrase.toUpperCase());

export const maskPhrase = phrase => [...phrase].map(char => char === ' ' ? ' ' : '_');

export const getAllIndexes = (arr, val) => {
  let indexes = [];

  for(let i = 0; i < arr.length; i++) {
    if (arr[i] === val) {
      indexes = [...indexes, i];
    }
  }

  return indexes;
}

export const countLetters = ([...word]) => word.filter(char => char !== ' ').length;
