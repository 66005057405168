import Letters from "./components/letters";
import {useState, useEffect} from "react";
import Phrase from "./components/phrase";
import {randomArrayItem, hiddenPhrases, maskPhrase, getAllIndexes, countLetters} from "./utils";

const maxMistakes = 4;

export default function App() {
  const [usedLetters, setUsedLetters] = useState([]);
  const [mistakes, setMistakes] = useState(0);
  const [phraseToGuess, setPhraseToGuess] = useState(randomArrayItem(hiddenPhrases));
  const [phraseTotal, setPhraseTotal] = useState(countLetters(phraseToGuess));
  const [currentLetter, setCurrentLetter] = useState();
  const [currentState, setCurrentState] = useState(maskPhrase(phraseToGuess));
  const [isWin, setIsWin] = useState(false);
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    if (!phraseTotal) {
      setIsWin(true);
      setIsEnd(true);
    }
  }, [phraseTotal])

  useEffect(() => {
    if (maxMistakes === mistakes) {
      setIsWin(false);
      setIsEnd(true);
    }
  }, [mistakes])

  useEffect(() => {
    setCurrentState(maskPhrase(phraseToGuess))
  }, [phraseToGuess])

  const newPhraseToGuess = () => {
    setPhraseToGuess(randomArrayItem(hiddenPhrases))
  };

  useEffect(() => {
    setPhraseTotal(countLetters(phraseToGuess))
  }, [phraseToGuess])

  const resetGame = () => {
    newPhraseToGuess();
    setMistakes(0);
    setUsedLetters([]);
    setCurrentLetter();
    setIsWin(false);
    setIsEnd(false);
    document.querySelectorAll('.letter--used').forEach(letter => letter.classList.remove('letter--used'));
  }

  useEffect(() => {
    setUsedLetters(prevState => ([...prevState, currentLetter]))
  }, [currentLetter])

  useEffect(() => {
    if ([...phraseToGuess].includes(currentLetter)) {
      setCurrentState(prevState => {
        const indexes = getAllIndexes([...phraseToGuess], currentLetter)

        if (!indexes.length) {
          return prevState;
        }

        const mainWordLetterTotal = prevState.length;
        let newState = [...prevState];

        for (let i = 0; i <= mainWordLetterTotal; i++) {
          if (indexes.includes(i)) {
            newState[i] = currentLetter;
            setPhraseTotal(prev => prev - 1);
          }
        }

        return newState;
      })
    }
  }, [currentLetter, phraseToGuess]);

  const handleLetterClick = e => {
    if (isWin || isEnd) {
      return;
    }

    const target = e.currentTarget;
    const letter = target.textContent;

    if (usedLetters.includes(letter)) {
      return;
    }

    if (![...phraseToGuess].includes(currentLetter)) {
      setMistakes(prev => prev + 1);
    }

    setCurrentLetter(letter);

    target.classList.add('letter--used');
  }

  const MistakesLines = () => {
    return (
      <div className="mistakes">
        {Array.from({length: mistakes}).map((_, index) => <span key={index}>🙈</span>)}
      </div>
    )
  }

  return (
    <div>
      <Phrase hiddenPhraseLetters={currentState}/>
      <Letters handleLetterClick={handleLetterClick}/>
      {isWin && <h1 style={{textAlign: 'center'}}>🎉 Brawo!</h1>}
      {isEnd && !isWin && <h1 style={{textAlign: 'center'}}>🙈 Game Over!</h1>}
      <MistakesLines />
      <div className="debug">
        <button type="button" onClick={()=> console.log(usedLetters)}>Check *used letters* 🦜</button>
        <button type="button" onClick={()=> console.log(mistakes)}>Check *mistakes* 🦜</button>
        <button type="button" onClick={()=> console.log(phraseToGuess)}>Check *phrase* 🦜</button>
        <button type="button" onClick={()=> resetGame()}>Reset</button>
        <button onClick={()=> console.log(currentLetter)}>Check *currentLetter*</button>
        <button onClick={()=> console.log(currentState)}>Check *currentState*</button>
      </div>
    </div>
  );
}
